<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card header="基本信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">水发公司</span>
                    <span class="property-value">{{user.companyName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">类型</span>
                    <span class="property-value">{{user.typeName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col v-if="user.type==2" :span="12" class="property-group">
                    <span class="property-name">姓名</span>
                    <span class="property-value">
                        {{user.name}}
                        <el-button type="text" title="复制" class="p-0 text-md" @click="$copy(user.name)">
                            <font-awesome-icon :icon="['far','copy']" />
                        </el-button>
                    </span>
                </el-col>
                <el-col v-if="user.type==3" :span="12" class="property-group">
                    <span class="property-name">企业名称</span>
                    <span class="property-value">{{user.name}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">电话</span>
                    <span class="property-value">
                        {{user.phone}}
                        <el-button type="text" title="复制" class="p-0 text-md" @click="$copy(user.phone)">
                            <font-awesome-icon :icon="['far','copy']" />
                        </el-button>
                    </span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">注册时间</span>
                    <span class="property-value">{{$moment.sdt(user.createTime)}}</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="认证信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">认证状态</span>
                    <span class="property-value">{{user.authenticationStatusName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col v-if="user.type==2" :span="12" class="property-group">
                    <span class="property-name">身份证号</span>
                    <span class="property-value">
                        {{user.idCard}}
                        <el-button type="text" title="复制" class="p-0 text-md" @click="$copy(user.idCard)">
                            <font-awesome-icon :icon="['far','copy']" />
                        </el-button>
                    </span>
                </el-col>
                <el-col v-if="user.type==3" :span="12" class="property-group">
                    <span class="property-name">统一社会信用代码</span>
                    <span class="property-value">{{user.idCard}}</span>
                </el-col>
                <el-col v-if="user.type==3" :span="12" class="property-group">
                    <span class="property-name">法人</span>
                    <span class="property-value">{{user.legalPersonName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col v-if="user.type==2" :span="12" class="property-group">
                    <div class="property-name">身份证人像面</div>
                    <div class="bg-light rounded">
                        <el-image :src="user.idCardProUrl" :preview-src-list="[user.idCardProUrl]" fit="contain"
                            class="w-100" style="height: 200px;" />
                    </div>
                </el-col>
                <el-col v-if="user.type==2" :span="12" class="property-group">
                    <div class="property-name">身份证国徽面</div>
                    <div class="bg-light rounded">
                        <el-image :src="user.idCardConUrl" :preview-src-list="[user.idCardConUrl]" fit="contain"
                            class="w-100" style="height: 200px;" />
                    </div>
                </el-col>
                <el-col v-if="user.type==3" :span="12" class="property-group">
                    <div class="property-name">营业执照</div>
                    <div class="bg-light rounded">
                        <el-image :src="user.businessLicenseUrl" :preview-src-list="[user.businessLicenseUrl]"
                            fit="contain" class="w-100" style="height: 200px;" />
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <el-card v-if="user.type==2" header="家庭成员" shadow="never" class="m-3">
            <el-table :data="familyMembers">
                <el-table-column prop="name" label="姓名" />
                <el-table-column prop="phone" label="电话" />
                <el-table-column prop="idCard" label="身份证号" />
                <el-table-column prop="relation" label="关系" />
                <el-table-column prop="job" label="职业" />
                <el-table-column prop="workUnit" label="工作单位" />
                <el-table-column prop="remark" label="备注" />
            </el-table>
        </el-card>

        <el-card v-if="user.type==2" header="用户资产" shadow="never" class="m-3">
            <el-table :data="userAssets">
                <el-table-column prop="typeName" label="类型" />
                <el-table-column prop="name" label="名称" />
                <el-table-column prop="purchaseValue" label="购置价值" />
                <el-table-column prop="quantity" label="数量" />
                <el-table-column prop="purchaseTime" label="购置时间" :formatter="$moment.sdFormatter" />
                <el-table-column prop="remark" label="备注" />
            </el-table>
        </el-card>

        <el-card v-if="partner.id" header="土地来源方信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">联系人</span>
                    <span class="property-value">{{partner.contactName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">联系电话</span>
                    <span class="property-value">{{partner.contactPhone}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">行政区划</span>
                    <span class="property-value">{{partner.regionName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">地址</span>
                    <span class="property-value">{{partner.address}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">开户行</span>
                    <span class="property-value">{{partner.openingBank}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">开户名</span>
                    <span class="property-value">{{partner.accountName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">行号</span>
                    <span class="property-value">{{partner.bankNumber}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">银行账号</span>
                    <span class="property-value">{{partner.accountNumber}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">备注</span>
                    <span class="property-value">{{partner.remark}}</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card v-if="farmer.id" header="种植户信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">{{farmer.statusName}}</span>
                </el-col>
            </el-row>

            <el-row v-if="user.type==3" :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">法人</span>
                    <span class="property-value">{{farmer.legalPersonName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">法人电话</span>
                    <span class="property-value">{{farmer.legalPersonPhone}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">联系人</span>
                    <span class="property-value">{{farmer.contactName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">联系电话</span>
                    <span class="property-value">{{farmer.contactPhone}}</span>
                </el-col>
            </el-row>

            <div class="property-group">
                <div class="property-name">征信相关附件</div>
                <div>
                    <picture-uploader v-model="farmer.creditAttachments" readonly />
                    <div v-if="farmer.creditAttachments.length==0" class="bg-light rounded p-3">
                        暂无数据
                    </div>
                </div>
            </div>

            <div class="property-group">
                <div class="property-name">失信相关附件</div>
                <div>
                    <picture-uploader v-model="farmer.discreditAttachments" readonly />
                    <div v-if="farmer.discreditAttachments.length==0" class="bg-light rounded p-3">
                        暂无数据
                    </div>
                </div>
            </div>

            <div class="property-group">
                <div class="property-name">其他附件</div>
                <div>
                    <picture-uploader v-model="farmer.otherAttachments" readonly />
                    <div v-if="farmer.otherAttachments.length==0" class="bg-light rounded p-3">
                        暂无数据
                    </div>
                </div>
            </div>
        </el-card>

        <el-card v-if="farmer.id" header="种植合同" shadow="never" class="m-3">
            <el-table :data="plantContracts">
                <el-table-column prop="year" label="年份" sortable width="100" />
                <el-table-column prop="code" label="编号" sortable width="150">
                    <template #default="scope">
                        <router-link :to="'/plant-contract/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.code}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column prop="farmerName" label="种植户" sortable />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-card v-if="warrantor.id" header="担保人" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">{{warrantor.statusName}}</span>
                </el-col>
            </el-row>

            <div class="property-group">
                <div class="property-name">担保对象</div>
                <div>
                    <el-table :data="warrantor.warrantedObjects">
                        <el-table-column prop="idCard" label="身份证号" />
                        <el-table-column prop="name" label="姓名" />
                    </el-table>
                </div>
            </div>
        </el-card>

        <el-card v-if="supplier.id" header="供应商信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">{{supplier.statusName}}</span>
                </el-col>
            </el-row>

            <el-row v-if="user.type==3" :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">法人</span>
                    <span class="property-value">{{supplier.legalPersonName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">法人电话</span>
                    <span class="property-value">{{supplier.legalPersonPhone}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">联系人</span>
                    <span class="property-value">{{supplier.contactName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">联系电话</span>
                    <span class="property-value">{{supplier.contactPhone}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">主营品牌</span>
                    <span class="property-value">{{supplier.mainBrand}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">行政区划</span>
                    <span class="property-value">{{supplier.regionName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">地址</span>
                    <span class="property-value">{{supplier.address}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">开户行</span>
                    <span class="property-value">{{supplier.openingBank}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">开户名</span>
                    <span class="property-value">{{supplier.accountName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">行号</span>
                    <span class="property-value">{{supplier.bankNumber}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">银行账号</span>
                    <span class="property-value">{{supplier.accountNumber}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">备注</span>
                    <span class="property-value">{{supplier.remark}}</span>
                </el-col>
            </el-row>

            <div class="property-group">
                <div class="property-name">其他附件</div>
                <div>
                    <picture-uploader v-model="supplier.otherAttachments" readonly />
                    <div v-if="supplier.otherAttachments.length==0" class="bg-light rounded p-3">
                        暂无数据
                    </div>
                </div>
            </div>
        </el-card>

        <el-card v-if="expert.id" header="专家信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">{{expert.statusName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">学历</span>
                    <span class="property-value">{{expert.educationName}}</span>
                </el-col>
            </el-row>

            <el-row v-if="user.type==3" :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">最高职称等级</span>
                    <span class="property-value">{{expert.highestTitleLevelName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">职称</span>
                    <span class="property-value">{{expert.title}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">擅长作物</span>
                    <span class="property-value">{{expert.goodAtCropNames}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">职业技能</span>
                    <span class="property-value">{{expert.professionalSkillNames}}</span>
                </el-col>
            </el-row>

            <div class="property-group">
                <div class="property-name">其他附件</div>
                <div>
                    <picture-uploader v-model="expert.attachments" readonly />
                    <div v-if="expert.attachments.length==0" class="bg-light rounded p-3">
                        暂无数据
                    </div>
                </div>
            </div>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">备注</span>
                    <span class="property-value">{{expert.remark}}</span>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                user: {},
                familyMembers: [],
                userAssets: [],
                partner: {},
                farmer: {},
                plantContracts: [],
                warrantor: {},
                supplier: {},
                expert: {},
            };
        },
        methods: {
            async loadUser() {
                let response = await this.$axios.get('/api/RegisteredUser/GetRegisteredUser', { params: { id: this.id } });
                this.user = response.data;
                document.title = this.user.name;
            },
            async loadFamilyMembers() {
                let response = await this.$axios.get('/api/FamilyMember/GetFamilyMembers', { params: { registeredUserId: this.id } });
                this.familyMembers = response.data;
            },
            async loadUserAssets() {
                let response = await this.$axios.get('/api/UserAsset/GetUserAssets', { params: { registeredUserId: this.id } });
                this.userAssets = response.data;
            },
            async loadPartner() {
                let response = await this.$axios.get('/api/Partner/GetPartner', { params: { id: this.id } });
                this.partner = response.data;
            },
            async loadFarmer() {
                let response = await this.$axios.get('/api/Farmer/GetFarmer', { params: { id: this.id } });
                this.farmer = response.data;
            },
            async loadPlantContracts() {
                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', { params: { companyId: this.$store.state.companyId, farmerId: this.id } });
                this.plantContracts = response.data.data;
            },
            async loadWarrantor() {
                let response = await this.$axios.get('/api/Warrantor/GetWarrantor', { params: { id: this.id } });
                this.warrantor = response.data;
            },
            async loadSupplier() {
                let response = await this.$axios.get('/api/Supplier/GetSupplier', { params: { id: this.id } });
                this.supplier = response.data;
            },
            async loadExpert() {
                let response = await this.$axios.get('/api/Expert/GetExpert', { params: { id: this.id } });
                this.expert = response.data;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadUser();
            this.loadFamilyMembers();
            this.loadUserAssets();
            this.loadPartner();
            this.loadFarmer();
            this.loadPlantContracts();
            this.loadWarrantor();
            this.loadSupplier();
            this.loadExpert();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>